import { environmentDefaults } from './enviroment.default';
import { environment as local } from './environment.local';
import { IDevEnvironment } from './IEnvironment';

export const environment: IDevEnvironment = {
  ...environmentDefaults,
  production: true,
  logLevel: 'log',
  debugMode: false,
  globalErrorHandler: false,

  access_token_key: 'APP_AUTH_ACCESS',
  refresh_token_key: 'APP_AUTH_REFRESH',
  auth_ic_our_routes: ['onclass.com', 'onclass.tech', 'onclass.space', 'onclass.local'],
  auth_ic_skip_routes: ['.css', '.js', 'assets/', 'web/auth/user-token', 'refresh', 'signout', 'signin'],
  interconn_key: '136b483a-2314-4d16-80c6-6c4f17e7537b4',
  token_url: 'https://auth.onclass.tech',
  cookie_domain: '.onclass.tech',
  default_host: 'https://onclass.tech',

  portal_url: 'https://onclass.tech',
  auth_url: 'https://b.onclass.com/web/auth',
  account_url: 'https://b.onclass.com/web/account',
  content_url: 'https://b.onclass.com/web/content',
  comment_url: 'https://b.onclass.com/web/comments',
  meetings_url: 'https://meet.onclass.com',
  files_url: 'https://b.onclass.com/web/files',
  messaging_url: 'https://b.onclass.com/web/messaging',
  schedule_url: 'https://b.onclass.com/web/account/schedule',
  chat_url: 'https://b.onclass.com/web/chat',
  ws_url: 'wss://b.onclass.com/ws',
  stripe_iframe_host: 'https://b.onclass.com/web/billing',
  billing_public_url: 'https://b.onclass.com/web/billing',

  i18n_url: 'https://i.onclass.com/i18n',
  i18n_en_url: 'https://i.onclass.com/i18n',
  is_i18n_developer: false,

  webSocketUrl: 'wss://b.onclass.com/ws',
  chatEndpoint: 'https://b.onclass.com/',
  sendbird_app_id: '67B48B78-63C2-43A2-9841-2B4F393C62B4',

  ...local,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
