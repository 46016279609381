"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountStatsSchema = void 0;
const zod_1 = require("zod");
exports.AccountStatsSchema = zod_1.z.object({
    /** Количество опубликованных уроков */
    lessons_count: zod_1.z.number().optional(),
    /** Опубликовано уроков за последний месяц */
    monthly_lessons_published: zod_1.z.number().optional(),
    /** Количество отзывов этому пользователю от других пользователей */
    reviews_count: zod_1.z.number().optional(),
    /** Оценка этому пользователю от других пользователей */
    reviews_rating: zod_1.z.number().optional(),
    /** Количество подписавшихся на этого пользователя */
    subscribers_count: zod_1.z.number().optional(),
    /** Количество подписок этого пользователя на других пользователей */
    subscriptions_count: zod_1.z.number().optional(),
    /** Количество опубликованных уроков */
    publications_count: zod_1.z.number().optional(),
    /** Количество митингов, проведённых этим пользователем */
    meetings_count: zod_1.z.number().optional(),
    /** Проведено занятий за последний месяц */
    monthly_meetings_provided: zod_1.z.number().optional(),
    /** Количество участников в митингах, проведённых этим пользователем */
    students_count: zod_1.z.number().optional(),
    /** Количество уроков, на которых был этот пользователь */
    classes_count: zod_1.z.number().optional(),
    /** Количество преподавателей, которые проводили митинги для этого пользователя */
    teachers_count: zod_1.z.number().optional(),
    /** Количество уроков, пройденных этим пользователем */
    lessons_completed: zod_1.z.number().optional(),
    /** Всего баллов получено за все пройденные уроки */
    lessons_points: zod_1.z.number().optional(),
    /** Минимальная цена услуги */
    min_price_tier: zod_1.z.number().optional(),
    /** Максимальная цена услуги */
    max_price_tier: zod_1.z.number().optional(),
    /** Общее количество услуг */
    services_count: zod_1.z.number().optional(),
    /** Количество опубликованных услуг */
    published_services_count: zod_1.z.number().optional(),
    /** Количество услуг прошедших модерацию */
    approved_services_count: zod_1.z.number().optional(),
    /** Количество активных услуг, на которые доступна запись */
    active_services_count: zod_1.z.number().optional(),
    /** Самая дальняя подходящяя дата старта среди активных услуг */
    latest_applicable_date: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    /** Количество свободных часов */
    available_hours: zod_1.z.number().optional(),
    /** Преподаваемые учителем предметы по урокам */
    lessons_subject_ids: zod_1.z.array(zod_1.z.number()).optional(),
    /** Преподаваемые учителем предметы по классам */
    classes_subject_ids: zod_1.z.array(zod_1.z.number()).optional(),
});
