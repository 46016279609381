"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorSchema = exports.ResponseSchema = exports.BodySchema = exports.method = exports.path = void 0;
exports.parseBody = parseBody;
exports.parseResponse = parseResponse;
exports.parseError = parseError;
const zod_1 = require("zod");
const common_1 = require("../../../../common");
const audience_1 = require("../../../../shared/audience");
/** относительно `${config.http.webAuthUrl}` */
exports.path = 'user-token';
exports.method = common_1.HttpMethod.GET;
// #region Request
exports.BodySchema = zod_1.z.object({
    email: zod_1.z.string().email().toLowerCase().trim(),
    password: zod_1.z.string().trim(),
    audience: zod_1.z.nativeEnum(audience_1.EnumAudience).default(audience_1.EnumAudience.web).optional(),
});
function parseBody(dto) {
    return exports.BodySchema.parse(dto);
}
// export const ParamsSchema = z.object({});
// export type Params = z.infer<typeof ParamsSchema>;
// export function parseParams(values: Params) {
//   return ParamsSchema.parse(values);
// }
// export const QuerySchema = z.object({});
// export type Query = z.infer<typeof QuerySchema>;
// export function parseQuery(values: Query) {
//   return QuerySchema.parse(values);
// }
// #endregion
// #region Response
exports.ResponseSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    slug: zod_1.z.string(),
    access: zod_1.z.string(),
    refresh: zod_1.z.string(),
});
function parseResponse(dto) {
    return exports.ResponseSchema.parse(dto);
}
exports.ErrorSchema = zod_1.z.object({
    error: zod_1.z.string(),
});
function parseError(dto) {
    return exports.ErrorSchema.parse(dto);
}
// #endregion
