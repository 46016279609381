"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseSchema = exports.ParamsSchema = exports.method = exports.path = void 0;
exports.parseParams = parseParams;
exports.parseResponse = parseResponse;
const zod_1 = require("zod");
const common_1 = require("../../../../common");
const lingo2_models_1 = require("lingo2-models");
/** Относительно `${config.http.panelUrl}` */
exports.path = 'account/:account_id/segment/:segment';
exports.method = common_1.HttpMethod.DELETE;
// #region Request
// Тело запроса. Optional
// export const BodySchema = z.null();
// export type Body = z.infer<typeof BodySchema>;
// export function parseBody(values: Body) {
//   return BodySchema.parse(values);
// }
// Параметры запроса. Optional
exports.ParamsSchema = zod_1.z.object({
    account_id: zod_1.z.string().uuid(),
    segment: zod_1.z.coerce.number().pipe(zod_1.z.nativeEnum(lingo2_models_1.UserSegmentEnum)),
});
function parseParams(values) {
    return exports.ParamsSchema.parse(values);
}
// Query параметры запроса. Optional
// export const QuerySchema = z.object({});
// export type Query = z.infer<typeof QuerySchema>;
// export function parseQuery(values: Query) {
//   return QuerySchema.parse(values);
// }
// #endregion
// #region Response
exports.ResponseSchema = common_1.AccountSchema;
function parseResponse(values) {
    return exports.ResponseSchema.parse(values);
}
// #endregion
