"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.notices = exports.GenericNoticeEvent = exports.GenericNotice = exports.NoticeProperties = void 0;
var lodash_1 = require("lodash");
var enums_1 = require("./enums");
var notice_params_1 = require("./notice-params");
exports.NoticeProperties = [
    'id',
    'type',
    'title',
    'user_id',
    'params',
    'created_at',
    'available_until',
];
var GenericNotice = /** @class */ (function () {
    function GenericNotice(values) {
        values.params = (0, notice_params_1.castNoticeParams)(values.params);
        values.created_at = values.created_at ? new Date(values.created_at) : null;
        values.available_until = values.available_until ? new Date(values.available_until) : null;
        (0, lodash_1.assign)(this, (0, lodash_1.pick)(values, ['id', 'type', 'title', 'user_id', 'params', 'created_at', 'available_until']));
    }
    return GenericNotice;
}());
exports.GenericNotice = GenericNotice;
var GenericNoticeEvent = /** @class */ (function () {
    function GenericNoticeEvent(values) {
        this.event = 'notice';
        this.level = values.level;
        this.notice = new GenericNotice(values.notice);
    }
    return GenericNoticeEvent;
}());
exports.GenericNoticeEvent = GenericNoticeEvent;
exports.notices = (_a = {},
    _a[enums_1.NoticeCategoryEnum.feeback] = (_b = {},
        _b[enums_1.NoticeGroupEnum.chat] = [enums_1.NoticeTypeEnum.private_chat_message],
        _b[enums_1.NoticeGroupEnum.like] = [enums_1.NoticeTypeEnum.content_like],
        _b[enums_1.NoticeGroupEnum.share] = [enums_1.NoticeTypeEnum.content_share],
        _b[enums_1.NoticeGroupEnum.comment] = [enums_1.NoticeTypeEnum.content_comment],
        _b[enums_1.NoticeGroupEnum.mention] = [enums_1.NoticeTypeEnum.content_comment_mention, enums_1.NoticeTypeEnum.chat_mention],
        _b),
    _a[enums_1.NoticeCategoryEnum.event] = (_c = {},
        _c[enums_1.NoticeGroupEnum.core] = [enums_1.NoticeTypeEnum.site_update],
        _c[enums_1.NoticeGroupEnum.subscriber] = [enums_1.NoticeTypeEnum.subscriber],
        _c[enums_1.NoticeGroupEnum.group] = [enums_1.NoticeTypeEnum.group_invite],
        _c[enums_1.NoticeGroupEnum.birthday] = [enums_1.NoticeTypeEnum.birthday],
        _c[enums_1.NoticeGroupEnum.schedule] = [
            enums_1.NoticeTypeEnum.meeting_soon,
            enums_1.NoticeTypeEnum.my_meeting_soon,
            enums_1.NoticeTypeEnum.schedule_tomorrow,
            enums_1.NoticeTypeEnum.schedule_today,
            enums_1.NoticeTypeEnum.meeting_started,
            enums_1.NoticeTypeEnum.my_meeting_started,
            enums_1.NoticeTypeEnum.meeting_disrupted,
            enums_1.NoticeTypeEnum.my_meeting_disrupted,
            enums_1.NoticeTypeEnum.meeting_time_changed,
            enums_1.NoticeTypeEnum.meeting_with_teacher_will_start,
            enums_1.NoticeTypeEnum.meeting_with_student_will_start,
        ],
        _c[enums_1.NoticeGroupEnum.payment] = [
            enums_1.NoticeTypeEnum.payment,
            enums_1.NoticeTypeEnum.meeting_was_purchased,
            enums_1.NoticeTypeEnum.meeting_purchase_was_canceled,
            enums_1.NoticeTypeEnum.billing_general_success,
            enums_1.NoticeTypeEnum.billing_general_warning,
            enums_1.NoticeTypeEnum.billing_general_error,
            enums_1.NoticeTypeEnum.billing_currency_purchased,
            enums_1.NoticeTypeEnum.billing_operation_completed,
            enums_1.NoticeTypeEnum.billing_operation_error,
            enums_1.NoticeTypeEnum.billing_card_update,
            enums_1.NoticeTypeEnum.contract_reservation,
            enums_1.NoticeTypeEnum.contract_signup_order_unpaid,
            enums_1.NoticeTypeEnum.contract_prolong_order_unpaid,
            enums_1.NoticeTypeEnum.contract_was_broken,
            enums_1.NoticeTypeEnum.contract_was_canceled,
            // TODO ? начисление бесплатных кредитов
            // TODO ? покупка кредитов
            // TODO ? списание кредитов
            // TODO ? начисление кредитов по плану
            // TODO ? подписка на план активирована
            // TODO ? подписка на план деактивирована
            // TODO ? план улучшен
        ],
        _c[enums_1.NoticeGroupEnum.rating] = [
            enums_1.NoticeTypeEnum.rating_change,
            enums_1.NoticeTypeEnum.rating_increased,
            enums_1.NoticeTypeEnum.rating_decreased,
        ],
        _c[enums_1.NoticeGroupEnum.review] = [
            enums_1.NoticeTypeEnum.meeting_review,
            enums_1.NoticeTypeEnum.leave_meeting_review,
            enums_1.NoticeTypeEnum.new_meeting_review,
        ],
        _c[enums_1.NoticeGroupEnum.relevant_search] = [enums_1.NoticeTypeEnum.relevant_teacher],
        _c[enums_1.NoticeGroupEnum.meeting] = [
            enums_1.NoticeTypeEnum.meeting_reservation,
            enums_1.NoticeTypeEnum.meeting_invite,
            enums_1.NoticeTypeEnum.individual_meeting,
            enums_1.NoticeTypeEnum.participant_canceled_meeting,
            enums_1.NoticeTypeEnum.teacher_canceled_meeting,
            enums_1.NoticeTypeEnum.teacher_rescheduled_meeting,
            enums_1.NoticeTypeEnum.participant_rescheduled_meeting,
            enums_1.NoticeTypeEnum.service_signed_up,
            enums_1.NoticeTypeEnum.contract_signed_up,
            enums_1.NoticeTypeEnum.contract_reservation,
            enums_1.NoticeTypeEnum.you_successfully_enrolled_in_meeting,
            enums_1.NoticeTypeEnum.student_successfully_enrolled_in_meeting,
            enums_1.NoticeTypeEnum.meeting_participant_joined,
        ],
        _c[enums_1.NoticeGroupEnum.payout] = [enums_1.NoticeTypeEnum.payout, enums_1.NoticeTypeEnum.meeting_payout],
        _c[enums_1.NoticeGroupEnum.task] = [enums_1.NoticeTypeEnum.assigned_task, enums_1.NoticeTypeEnum.assessed_task, enums_1.NoticeTypeEnum.done_task],
        _c[enums_1.NoticeGroupEnum.content] = [enums_1.NoticeTypeEnum.sub_content, enums_1.NoticeTypeEnum.relevant_content],
        _c[enums_1.NoticeGroupEnum.profile] = [
            enums_1.NoticeTypeEnum.profile_verified,
            enums_1.NoticeTypeEnum.profile_declined,
            enums_1.NoticeTypeEnum.teacher_rank_warning,
            enums_1.NoticeTypeEnum.teacher_rank_change,
        ],
        _c[enums_1.NoticeGroupEnum.school] = [enums_1.NoticeTypeEnum.school_status_changed],
        _c),
    _a);
