"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.castNoticeParams = void 0;
var enums_1 = require("../enums");
var billing_credits_purchased_notice_params_1 = require("./billing-credits-purchased.notice-params");
var billing_plan_was_changed_notice_params_1 = require("./billing-plan-was-changed.notice-params");
var billing_plan_was_deactivated_notice_params_1 = require("./billing-plan-was-deactivated.notice-params");
var classroom_new_invitation_2_notice_params_1 = require("./classroom-new-invitation-2.notice-params");
var classroom_new_invitation_notice_params_1 = require("./classroom-new-invitation.notice-params");
var classroom_new_participant_2_notice_params_1 = require("./classroom-new-participant-2.notice-params");
var classroom_new_participant_notice_params_1 = require("./classroom-new-participant.notice-params");
var content_like_notice_params_1 = require("./content-like.notice-params");
var contract_prolong_order_unpaid_notice_params_1 = require("./contract-prolong-order-unpaid.notice-params");
var contract_reservation_notice_params_1 = require("./contract-reservation.notice-params");
var contract_signed_up_student_notice_params_1 = require("./contract-signed-up-student.notice-params");
var contract_signed_up_notice_params_1 = require("./contract-signed-up.notice-params");
var contract_signup_order_unpaid_notice_params_1 = require("./contract-signup-order-unpaid.notice-params");
var contract_was_broken_notice_params_1 = require("./contract-was-broken.notice-params");
var contract_was_canceled_notice_params_1 = require("./contract-was-canceled.notice-params");
var meeting_complaint_reported_notice_params_1 = require("./meeting-complaint-reported.notice-params");
var meeting_complaint_resolved_reporter_notice_params_1 = require("./meeting-complaint-resolved-reporter.notice-params");
var meeting_complaint_resolved_teacher_notice_params_1 = require("./meeting-complaint-resolved-teacher.notice-params");
var meeting_participant_joined_notice_params_1 = require("./meeting-participant-joined.notice-params");
var meeting_purchase_was_canceled_notice_params_1 = require("./meeting-purchase-was-canceled.notice-params");
var meeting_soon_2_notice_params_1 = require("./meeting-soon-2.notice-params");
var meeting_soon_notice_params_1 = require("./meeting-soon.notice-params");
var meeting_time_changed_notice_params_1 = require("./meeting-time-changed.notice-params");
var meeting_with_student_will_start_notice_params_1 = require("./meeting-with-student-will-start.notice-params");
var meeting_with_teacher_will_start_notice_params_1 = require("./meeting-with-teacher-will-start.notice-params");
var new_meeting_review_notice_params_1 = require("./new-meeting-review.notice-params");
var plan_will_be_prolong_soon_notice_params_1 = require("./plan-will-be-prolong-soon-notice-params");
var rating_decreased_notice_params_1 = require("./rating-decreased.notice-params");
var rating_increased_notice_params_1 = require("./rating-increased.notice-params");
var student_successfully_enrolled_in_meeting_notice_params_1 = require("./student-successfully-enrolled-in-meeting.notice-params");
var vacation_reminder_notice_params_1 = require("./vacation-reminder.notice-params");
var vacation_set_up_notice_params_1 = require("./vacation-set-up.notice-params");
var you_successfully_enrolled_in_meeting_notice_params_1 = require("./you-successfully-enrolled-in-meeting.notice-params");
var meeting_will_start_notice_params_1 = require("./meeting-will-start.notice-params");
var map = (_a = {},
    _a[enums_1.NoticeTypeEnum.classroom_new_invitation] = classroom_new_invitation_notice_params_1.ClassroomNewInvitationNoticeParams,
    _a[enums_1.NoticeTypeEnum.classroom_new_participant] = classroom_new_participant_notice_params_1.ClassroomNewParticipantNoticeParams,
    _a[enums_1.NoticeTypeEnum.content_like] = content_like_notice_params_1.ContentLikeNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_signed_up] = contract_signed_up_notice_params_1.ContractSignedUpNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_reservation] = contract_reservation_notice_params_1.ContractReservationNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_signup_order_unpaid] = contract_signup_order_unpaid_notice_params_1.ContractSignupOrderUnpaidNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_prolong_order_unpaid] = contract_prolong_order_unpaid_notice_params_1.ContractProlongOrderUnpaidNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_was_broken] = contract_was_broken_notice_params_1.ContractWasBrokenNoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_was_canceled] = contract_was_canceled_notice_params_1.ContractWasCanceledNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_participant_joined] = meeting_participant_joined_notice_params_1.MeetingParticipantJoinedNoticeParams,
    _a[enums_1.NoticeTypeEnum.billing_credits_purchased] = billing_credits_purchased_notice_params_1.BillingCreditsPurchasedNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_time_changed] = meeting_time_changed_notice_params_1.MeetingTimeChangedNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_soon] = meeting_soon_notice_params_1.MeetingSoonNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_purchase_was_canceled] = meeting_purchase_was_canceled_notice_params_1.MeetingPurchaseWasCanceledNoticeParams,
    _a[enums_1.NoticeTypeEnum.billing_plan_was_changed] = billing_plan_was_changed_notice_params_1.BillingPlanWasChangedNoticeParams,
    _a[enums_1.NoticeTypeEnum.billing_plan_was_deactivated] = billing_plan_was_deactivated_notice_params_1.BillingPlanWasDeactivatedNoticeParams,
    _a[enums_1.NoticeTypeEnum.classroom_new_invitation_2] = classroom_new_invitation_2_notice_params_1.ClassroomNewInvitation2NoticeParams,
    _a[enums_1.NoticeTypeEnum.classroom_new_participant_2] = classroom_new_participant_2_notice_params_1.ClassroomNewParticipant2NoticeParams,
    _a[enums_1.NoticeTypeEnum.contract_signed_up_student] = contract_signed_up_student_notice_params_1.ContractSignedUpStudentNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_complaint_reported] = meeting_complaint_reported_notice_params_1.MeetingComplaintReportedNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_complaint_resolved_reporter] = meeting_complaint_resolved_reporter_notice_params_1.MeetingComplaintResolvedReporterNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_complaint_resolved_teacher] = meeting_complaint_resolved_teacher_notice_params_1.MeetingComplaintResolvedTeacherNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_soon_2] = meeting_soon_2_notice_params_1.MeetingSoon2NoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_with_student_will_start] = meeting_with_student_will_start_notice_params_1.MeetingWithStudentWillStartNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_with_teacher_will_start] = meeting_with_teacher_will_start_notice_params_1.MeetingWithTeacherWillStartNoticeParams,
    _a[enums_1.NoticeTypeEnum.billing_plan_will_be_prolong_soon] = plan_will_be_prolong_soon_notice_params_1.PlanWillBeProlongSoonNoticeParams,
    _a[enums_1.NoticeTypeEnum.student_successfully_enrolled_in_meeting] = student_successfully_enrolled_in_meeting_notice_params_1.StudentSuccessfullyEnrolledInMeetingNoticeParams,
    _a[enums_1.NoticeTypeEnum.vacation_reminder] = vacation_reminder_notice_params_1.VacationReminderNoticeParams,
    _a[enums_1.NoticeTypeEnum.vacation_set_up] = vacation_set_up_notice_params_1.VacationSetUpNoticeParams,
    _a[enums_1.NoticeTypeEnum.you_successfully_enrolled_in_meeting] = you_successfully_enrolled_in_meeting_notice_params_1.YouSuccessfullyEnrolledInMeetingNoticeParams,
    _a[enums_1.NoticeTypeEnum.new_meeting_review] = new_meeting_review_notice_params_1.NewMeetingReviewNoticeParams,
    _a[enums_1.NoticeTypeEnum.rating_decreased] = rating_decreased_notice_params_1.RatingDecreasedNoticeParams,
    _a[enums_1.NoticeTypeEnum.rating_increased] = rating_increased_notice_params_1.RatingIncreasedNoticeParams,
    _a[enums_1.NoticeTypeEnum.meeting_will_start] = meeting_will_start_notice_params_1.MeetingWillStartNoticeParams,
    _a);
function castNoticeParams(noticeParams) {
    if (noticeParams.type in map && map[noticeParams.type]) {
        var _ctor = map[noticeParams.type];
        return _ctor.createInstance(noticeParams);
    }
    return noticeParams;
    /**
     * TODO [https://app.clickup.com/t/862kagrkq]
     *   Перейти от 'подразумеваемых' типов на базе implements INoticeParams
     *   к конкретным типам на базе extends AbstractNoticeParams
     *   throw new Error(`Notice Params '${noticeParams.type}'
     *   not supported: ` + JSON.stringify(noticeParams.type, null, 2));
     */
}
exports.castNoticeParams = castNoticeParams;
