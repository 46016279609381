"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumAudience = void 0;
var EnumAudience;
(function (EnumAudience) {
    EnumAudience["web"] = "web";
    EnumAudience["ios"] = "ios";
    EnumAudience["android"] = "android";
    EnumAudience["electron"] = "electron";
    EnumAudience["nodekit"] = "nodekit";
})(EnumAudience || (exports.EnumAudience = EnumAudience = {}));
;
