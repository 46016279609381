"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = exports.UserServiceProperties = void 0;
var account_1 = require("../../account");
var school_1 = require("../../school");
var meeting_1 = require("../meeting");
exports.UserServiceProperties = [
    'id',
    'title',
    'type',
    'excerpt',
    'description',
    'level_id',
    'level',
    'participants_limit',
    'price_tier',
    'slug',
    'cover_id',
    'cover',
    'author_id',
    'author',
    'keywords',
    'subject',
    'subject_id',
    'subject_other_id',
    'subject_other_name',
    'language',
    'language_id',
    'category',
    'category_id',
    'duration',
    'discount',
    'discount_rule',
    'media_url',
    'publish_status',
    'moderation_status',
    'moderation_message',
    'promotion',
    'promotion_status',
    'advertisement',
    'advertisement_status',
    'stats',
    'visit_info',
    'public',
    'created_at',
    'published_at',
    'archived_at',
    'purl',
    'hidden',
    'readonly',
    'school_id',
    'school',
    'teachers',
    'teacher_ids',
    'options',
    'can',
    'is',
    '__opts',
];
var UserServiceDateProperties = ['published_at', 'archived_at', 'created_at', 'changed_at'];
var UserService = /** @class */ (function () {
    function UserService(values) {
        if (values === void 0) { values = {}; }
        /** Правила предоставления скидки */
        this.discount_rule = meeting_1.MeetingDiscountRuleEnum.none;
        var properties = exports.UserServiceProperties;
        var instance = this;
        if (!values) {
            return instance;
        }
        properties.forEach(function (p) {
            if (p in values) {
                instance[p] = values[p];
            }
        });
        // преобразование дат
        UserServiceDateProperties.forEach(function (p) {
            if (p in instance && instance[p]) {
                instance[p] = new Date(instance[p]);
            }
        });
        if ('author' in values) {
            instance.author = new account_1.User(values.author);
        }
        if ('school' in values) {
            instance.school = new school_1.CSchool(values.school);
        }
        // subject
        // language
        // level
        // category
        instance.media = values.media || [];
    }
    return UserService;
}());
exports.UserService = UserService;
