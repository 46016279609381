import { environmentDefaults } from './enviroment.default';
import { IDevEnvironment } from './IEnvironment';

/*
# CDN забирает данные с meet.onclass.tech для раздачи через cdn-meet.onclass.tech
# see https://selfcare.cdnnow.ru/projects/e8db5d7b-0138-4b3f-a4cb-e0bd35644f62
#
# see https://www.reg.ru/user/account/#/card/55095507/nss
#   CNAME cdn-meet → user32892.clients-cdnnow.ru
*/
const ASSETS_HOST = 'cdn-meet.onclass.tech';

const I18N_HOST = 'i.onclass.tech';

export const environment: IDevEnvironment = {
  ...environmentDefaults,
  production: true,
  env: 'staging',
  logLevel: 'log',
  globalErrorHandler: true,
  debugMode: true,

  access_token_key: 'APP_AUTH_ACCESS',
  refresh_token_key: 'APP_AUTH_REFRESH',
  auth_ic_our_routes: ['onclass.com', 'onclass.tech', 'onclass.space', 'onclass.local'],
  auth_ic_skip_routes: ['.css', '.js', 'assets/', 'web/auth/user-token', 'refresh', 'signout', 'signin'],
  interconn_key: '136b483a-2314-4d16-80c6-6c4f17e7537b4',
  token_url: 'https://auth.onclass.tech',
  cookie_domain: '.onclass.tech',
  default_host: 'https://onclass.tech',

  portal_url: 'https://onclass.tech',
  auth_url: 'https://b.onclass.tech/web/auth',
  account_url: 'https://b.onclass.tech/web/account',
  content_url: 'https://b.onclass.tech/web/content',
  comment_url: 'https://b.onclass.tech/web/comments',
  meetings_url: 'https://meet.onclass.tech',
  files_url: 'https://b.onclass.tech/web/files',
  messaging_url: 'https://b.onclass.tech/web/messaging',
  schedule_url: 'https://b.onclass.tech/web/account/schedule',
  chat_url: 'https://b.onclass.tech/web/chat',
  ws_url: 'wss://b.onclass.tech/ws',
  stripe_iframe_host: 'https://b.onclass.tech/web/billing',
  billing_public_url: 'https://b.onclass.tech/web/billing',

  i18n_url: `//${I18N_HOST}/i18n`,
  i18n_en_url: `//${I18N_HOST}/i18n`,
  is_i18n_developer: false,

  webSocketUrl: 'wss://b.onclass.tech/ws',
  chatEndpoint: 'https://b.onclass.tech/',

  features: {
    ...environmentDefaults.features,
    chat: true,
    homeworks: false,
    individualTasks: false,
    replayHistory: false,
    meetingsHistory: false,
    vocabulary: true,
  },

  assets_url: `//${ASSETS_HOST}`,
  sendbird_app_id: '67B48B78-63C2-43A2-9841-2B4F393C62B4',
};
