"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractPackageSchema = exports.AccountPlanTerms20240118PackageSchema = void 0;
const lingo2_models_1 = require("lingo2-models");
const zod_1 = require("zod");
const plan_1 = require("./plan");
const currency_amount_1 = require("./currency-amount");
exports.AccountPlanTerms20240118PackageSchema = zod_1.z.object({
    package: zod_1.z.nativeEnum(lingo2_models_1.PackageEnum),
    timestamp: zod_1.z.number(),
    /** Идентификатор тарифного плана */
    plan_id: zod_1.z.string().uuid(),
    /** Индивидуальные опции плана */
    details: plan_1.PlanDetailsSchema.partial(),
    /** ID уровня оплаты */
    price_tier_id: zod_1.z.number().or(zod_1.z.null()).optional(),
    /** Фиксированный уровень оплаты */
    price: currency_amount_1.CurrencyAmountSchema.optional(),
    /** Периодичность оплаты */
    period: zod_1.z.nativeEnum(lingo2_models_1.PlanPeriodEnum),
});
exports.AbstractPackageSchema = zod_1.z.object({
    package: zod_1.z.nativeEnum(lingo2_models_1.PackageEnum),
    timestamp: zod_1.z.number(),
});
