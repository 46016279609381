"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeTypeEnum = void 0;
/**
 * Уведомления не будут работать, пока они не добавлены в группу уведомлений
 *
 * @see notices (../notice.ts)
 */
var NoticeTypeEnum;
(function (NoticeTypeEnum) {
    /**
     * Сайт был обновлён
     */
    NoticeTypeEnum[NoticeTypeEnum["site_update"] = 0] = "site_update";
    /**
     * Отладочное неподдерживаемое уведомление
     */
    NoticeTypeEnum[NoticeTypeEnum["debug_not_supported_notice"] = 100000] = "debug_not_supported_notice";
    /**
     * Новое сообщение
     * От: Patrick Richardson
     * Доброе утро. Вот, накидал на свежую голову как вижу флоу...
     *
     * @see IchatMessageNotice
     * @deprecated Вынес все чат сообщения в 100-120
     */
    NoticeTypeEnum[NoticeTypeEnum["private_chat_message"] = 1] = "private_chat_message";
    /**
     * Нравится
     * {User’s name} liked your post {lesson’s name}
     *
     * @see ContentLikeNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["content_like"] = 2] = "content_like";
    /**
     * Поделились
     * Patrick Richardson поделился вашим уроком Nuclear Chemistry
     *
     * @see IContentShareNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["content_share"] = 3] = "content_share";
    /**
     * Новый комментарий
     * Patrick Richardson оставил комментарий к вашей публикации Nuclear Chemistry
     *
     * @see IContentCommentNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["content_comment"] = 4] = "content_comment";
    /**
     * TODO
     * Упоминания
     * Kyle Lane упомянул вас в записи Nuclear Chemistry
     */
    NoticeTypeEnum[NoticeTypeEnum["content_comment_mention"] = 5] = "content_comment_mention";
    /**
     * Новый подписчик
     * Savannah Cooper подписалась на вас
     *
     * @see ISubscriberNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["subscriber"] = 7] = "subscriber";
    /**
     * TODO
     * Приглашение в группу
     * Julie Richards приглашает в группу Nuclear Chemistry
     * TODO @see IGroupInviteNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["group_invite"] = 8] = "group_invite";
    /**
     * TODO
     * День рождения!
     * Ralph Robertson Празднует день рождения!
     * TODO @see IBirthdayNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["birthday"] = 9] = "birthday";
    /**
     * @deprecated
     * @see NoticeTypeEnum.meeting_soon_2
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_soon"] = 10] = "meeting_soon";
    /**
     * @deprecated
     * @see NoticeTypeEnum.meeting_soon_2
     */
    NoticeTypeEnum[NoticeTypeEnum["my_meeting_soon"] = 11] = "my_meeting_soon";
    /**
     * Ожидание оплаты
     * У вас есть неоплаченное занятие Nuclear Chemistry 01/01/2020 15:00
     *
     * @see IMeetingReservationNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_reservation"] = 12] = "meeting_reservation";
    /**
     * @deprecated
     * @see NoticeTypeEnum.rating_increased
     * @see NoticeTypeEnum.rating_decreased
     */
    NoticeTypeEnum[NoticeTypeEnum["rating_change"] = 13] = "rating_change";
    /**
     * @deprecated
     * @see NoticeTypeEnum.new_meeting_review
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_review"] = 14] = "meeting_review";
    /**
     * Новый преподаватель по избранным дисциплинам
     * По вашим избранным дисциплинам появился новый преподаватель
     *
     * @see IRelevantTeacherNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["account_relevant_teacher_appeared"] = 16] = "account_relevant_teacher_appeared";
    /**
     * @deprecated
     * @see account_relevant_teacher_appeared
     */
    NoticeTypeEnum[NoticeTypeEnum["relevant_teacher"] = 15] = "relevant_teacher";
    /**
     * Приглашение на урок
     * Преподаватель Patrick Richardson приглашает вас на урок Nuclear Chemistry - 01/01/2020 15:00
     *
     * @see IMeetingInviteNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_invite"] = 16] = "meeting_invite";
    /**
     * Ваша выплата
     * +75 USD
     *
     * @see IPayoutNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["payout"] = 17] = "payout";
    /**
     * Новое домашнее задание
     * Преподаватель Victoria Howard  прислала вам домашнее задание
     * "I Think the Most Important Part of Bein...
     * Biology test №1
     * Rhythm and Meter in English Poetry
     *
     * @see IAssignedTaskNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["assigned_task"] = 18] = "assigned_task";
    /**
     * TODO
     * Новый пост от подписчика  --- почему от подписчика?
     * Patrick Richardson сделал публикацию впервые за долгое время.
     * Rhythm and Meter in English Poetry
     *
     * @see ISubContentNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["sub_content"] = 19] = "sub_content";
    /**
     * TODO
     */
    NoticeTypeEnum[NoticeTypeEnum["relevant_content"] = 20] = "relevant_content";
    /**
     * Use NoticeTypeEnum.user_service_contract_signed_up instead
     *
     * @deprecated
     * @see contract_signed_up
     */
    NoticeTypeEnum[NoticeTypeEnum["individual_meeting"] = 21] = "individual_meeting";
    /**
     * Ожидание оплаты
     * У вас есть неоплаченное занятие Nuclear Chemistry 01/01/2020 15:00
     *
     * @see IPaymentNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["payment"] = 22] = "payment";
    /**
     * TODO
     * Расписание на завтра
     * Завтра у Вас 2 занятия
     */
    NoticeTypeEnum[NoticeTypeEnum["schedule_tomorrow"] = 23] = "schedule_tomorrow";
    /**
     * TODO
     * Расписание на сегодня
     * Сегодня у Вас 2 занятия
     */
    NoticeTypeEnum[NoticeTypeEnum["schedule_today"] = 24] = "schedule_today";
    /**
     * Занятие началось
     * Началось индивидуальное занятие с преподавателем Victoria Howard
     *
     * @see IMeetingStartedNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_started"] = 25] = "meeting_started";
    /**
     * Занятие началось
     * Началось индивидуальное занятие с Victoria Howard
     *
     * @see IMyMeetingStartedNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["my_meeting_started"] = 26] = "my_meeting_started";
    /**
     * Отмена занятия
     * Ученик Patrick Richardson отменил занятие Nuclear Chemistry - 01/01/2020 15:00
     *
     * @see IParticipantCanceledMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["participant_canceled_meeting"] = 27] = "participant_canceled_meeting";
    /**
     * Отмена занятия
     * Преподаватель Patrick Richardson отменил занятие Nuclear Chemistry - 01/01/2020 15:00
     *
     * @see ITeacherCanceledMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["teacher_canceled_meeting"] = 28] = "teacher_canceled_meeting";
    /**
     * Перенос времени занятия
     * Преподаватель Patrick Richardson просит перенести занятие
     * Nuclear Chemistry - 01/01/2020 15:00 - новое время 02/02/2020 15:00
     *
     * @see ITeacherRescheduledMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["teacher_rescheduled_meeting"] = 29] = "teacher_rescheduled_meeting";
    /**
     * Перенос времени занятия
     * Ученик Patrick Richardson просит перенести занятие
     * Nuclear Chemistry - 01/01/2020 15:00 - новое время 02/02/2020 15:00
     *
     * @see IParticipantRescheduledMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["participant_rescheduled_meeting"] = 30] = "participant_rescheduled_meeting";
    /**
     * TODO
     * Домашнее задание
     * Преподаватель Victoria Howard  проверила Ваше домашнее задание
     * "I Think the Most Important Part of Bein...
     * Biology test №1
     * Rhythm and Meter in English Poetry
     */
    NoticeTypeEnum[NoticeTypeEnum["assessed_task"] = 31] = "assessed_task";
    /**
     * TODO
     * Домашнее задание
     * Ученик Victoria Howard  выполнила домашнее задание
     * "I Think the Most Important Part of Bein...
     * Biology test №1
     * Rhythm and Meter in English Poetry
     */
    NoticeTypeEnum[NoticeTypeEnum["done_task"] = 32] = "done_task";
    /**
     * Выплата за занятие
     * +75 USD
     *
     * @see IMeetingPayoutNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_payout"] = 33] = "meeting_payout";
    /**
     * Профиль был верифицирован
     *
     * @see IProfileVerificationNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["profile_verified"] = 34] = "profile_verified";
    /**
     * Профиль был отклонён
     *
     * @see IProfileVerificationNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["profile_declined"] = 35] = "profile_declined";
    /**
     * Вы не заполнили профиль
     */
    NoticeTypeEnum[NoticeTypeEnum["profile_incomplete"] = 36] = "profile_incomplete";
    /**
     * @deprecated
     * @see NoticeTypeEnum.meeting_with_student_will_start
     * @see NoticeTypeEnum.meeting_with_teacher_will_start
     * @see NoticeTypeEnum.meeting_will_start
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_soon_2"] = 37] = "meeting_soon_2";
    /**
     * Срыв занятия (для преподавателя)
     * Отменено ваше занятие Nuclear Chemistry, запланированное на 01/01/2020 15:00
     * Отменено ваше занятие Nuclear Chemistry c учеником Patrick Richardson, запланированное на 01/01/2020 15:00
     *
     * @see IDisruptedMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_disrupted"] = 38] = "meeting_disrupted";
    /**
     * Срыв занятия (для ученика)
     * Отменено занятие Nuclear Chemistry с преподавателем Patrick Richardson, запланированное на 01/01/2020 15:00
     *
     * @see IMyDisruptedMeetingNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["my_meeting_disrupted"] = 39] = "my_meeting_disrupted";
    /**
     * Ранг преподавателя может быть понижен
     * Ваш ранг будет понижен до <ранг>. Детали.
     *
     * @see ITeacherRankWarningNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["teacher_rank_warning"] = 40] = "teacher_rank_warning";
    /**
     * Ранг преподавателя изменён
     * Ваш ранг повышен до <ранг>
     * Ваш ранг понижен до <ранг>
     * Ваш ранг изменён на <ранг>
     *
     * @see ITeacherRankChangeNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["teacher_rank_change"] = 41] = "teacher_rank_change";
    /**
     * Ваш митинг был оплачен
     *
     * @see IMeetingPurchasedNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_was_purchased"] = 42] = "meeting_was_purchased";
    /**
     * Оплата митинга была прервана (для ученика)
     *
     * @see IMeetingPurchaseCanceledNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_purchase_was_canceled"] = 43] = "meeting_purchase_was_canceled";
    /**
     * Любое обновление занятия, которое не выделено в отдельное событие
     *
     * @see IMeetingUpdatedNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_updated"] = 44] = "meeting_updated";
    /**
     * @deprecated
     * @see NoticeTypeEnum.contract_signed_up
     */
    NoticeTypeEnum[NoticeTypeEnum["service_signed_up"] = 45] = "service_signed_up";
    /**
     * Пожалуйста, оцените занятие
     *
     * @see ILeaveMeetingReviewNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["leave_meeting_review"] = 46] = "leave_meeting_review";
    /**
     * Ученик присоединился к митингу
     *
     * @see MeetingParticipantJoinedNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_participant_joined"] = 47] = "meeting_participant_joined";
    /**
     * К вам записались на занятие(занятия) (для преподавателя)
     *
     * Ученик Patrick Richardson, занятие Nuclear Chemistry - 01/01/2020 15:00
     *
     * Ученик Patrick Richardson, регулярное занятие Nuclear Chemistry
     * 1 раз в неделю - ближайшее 01/01/2020 15:00
     *
     * Ученик Patrick Richardson, курс Nuclear Chemistry,
     * 2 занятия: 01/01/2020 15:00, 02/01/2020 15:00
     *
     * @see ContractSignedUpNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_signed_up"] = 48] = "contract_signed_up";
    /**
     * Ожидание оплаты занятий по услуге
     * Вы не закончили оформление на занятие Nuclear Chemistry 01/01/2020 15:00
     * Вы не закончили оформление на курс занятий Nuclear Chemistry 01/01/2020 15:00
     * Вы не закончили оформление регулярного занятия
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_reservation"] = 49] = "contract_reservation";
    /**
     * Не удалось оплатить запись на занятия по услуге
     *
     * Payment failed
     * Please try again later or use another payment method
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_signup_order_unpaid"] = 50] = "contract_signup_order_unpaid";
    /**
     * Не удалось оплатить продление регулярных занятий по услуге
     *
     * Payment failed. Please try again later or use another payment method
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_prolong_order_unpaid"] = 51] = "contract_prolong_order_unpaid";
    /**
     * Студент отменил занятия
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_was_broken"] = 52] = "contract_was_broken";
    /**
     * Занятия (все) по услуге отменены
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_was_canceled"] = 53] = "contract_was_canceled";
    /**
     * Время митинга было изменено
     * Teacher {{teacher}} rescheduled {{title}} class from {{old}} to new time {{new}}
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_time_changed"] = 54] = "meeting_time_changed";
    /**
     * Вы записались на занятие(занятия) по услуге (для ученика)
     *
     * Вы записались на занятие “Английский для начинающих”
     * Вы записались на мини-курс “Английский для начинающих”
     * Вы записались на регулярное занятие “Английский для начинающих”
     *
     * @see ContractSignedUpStudentNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["contract_signed_up_student"] = 55] = "contract_signed_up_student";
    /**
     * In {delay} minutes, the class {Class’s name} with tutor {User's name} will begin
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_with_teacher_will_start"] = 56] = "meeting_with_teacher_will_start";
    /**
     * In {delay} minutes, the class {Class’s name} with student {User’s name} will begin
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_with_student_will_start"] = 57] = "meeting_with_student_will_start";
    /**
     * New entry for the class {Class’s name}
     * Date and time: 10/02/2024 10:00
     * Tutor: {User’s name}
     */
    NoticeTypeEnum[NoticeTypeEnum["you_successfully_enrolled_in_meeting"] = 58] = "you_successfully_enrolled_in_meeting";
    /**
     * New entry for the class {Class’s name}
     * Date and time: 10/02/2024 10:00
     * Student: {User’s name}
     */
    NoticeTypeEnum[NoticeTypeEnum["student_successfully_enrolled_in_meeting"] = 59] = "student_successfully_enrolled_in_meeting";
    /**
     * Feedback about class {class’name} from student {user’s name}: {review text}
     */
    NoticeTypeEnum[NoticeTypeEnum["new_meeting_review"] = 60] = "new_meeting_review";
    /**
     * Your rating has been increased
     */
    NoticeTypeEnum[NoticeTypeEnum["rating_increased"] = 61] = "rating_increased";
    /**
     * Your rating has been lowered
     */
    NoticeTypeEnum[NoticeTypeEnum["rating_decreased"] = 62] = "rating_decreased";
    /**
     * In {delay} minutes, the class {Class’name} will begin
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_will_start"] = 63] = "meeting_will_start";
    /** * -------------- что-то во время модерации -------------------- */
    /**
     * Уведомление о новой жалобе на митинг
     *
     * @see MeetingComplaintReportedNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_complaint_reported"] = 95] = "meeting_complaint_reported";
    /**
     * Уведомление преподавателю о решении жалобы на митинг
     *
     * @see MeetingComplaintResolvedTeacherNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_complaint_resolved_teacher"] = 96] = "meeting_complaint_resolved_teacher";
    /**
     * Уведомление ученику о решении жалобы на митинг
     *
     * @see MeetingComplaintResolvedReporterNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["meeting_complaint_resolved_reporter"] = 97] = "meeting_complaint_resolved_reporter";
    /**
     * Поступило новое задание на модерацию
     */
    NoticeTypeEnum[NoticeTypeEnum["moderation_queue_task"] = 98] = "moderation_queue_task";
    /** Изменения контента, модерация */
    NoticeTypeEnum[NoticeTypeEnum["content_moderation_updated"] = 99] = "content_moderation_updated";
    /** * -------------- Чат -------------------- */
    /** Обновление треда */
    NoticeTypeEnum[NoticeTypeEnum["chat_thread_update"] = 100] = "chat_thread_update";
    /**
     * Новое сообщение
     * От: Patrick Richardson
     * Доброе утро. Вот, накидал на свежую голову как вижу флоу...
     *
     * @see IchatMessageNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["chat_message"] = 101] = "chat_message";
    /**
     * Вас упомянули
     */
    NoticeTypeEnum[NoticeTypeEnum["chat_mention"] = 102] = "chat_mention";
    /**
     * Начал печатать
     */
    NoticeTypeEnum[NoticeTypeEnum["chat_typing_start"] = 103] = "chat_typing_start";
    /**
     * Закончил печатать
     */
    NoticeTypeEnum[NoticeTypeEnum["chat_typing_end"] = 104] = "chat_typing_end";
    /** * -------------- Биллинг -------------------- */
    NoticeTypeEnum[NoticeTypeEnum["billing_general_success"] = 200] = "billing_general_success";
    NoticeTypeEnum[NoticeTypeEnum["billing_general_warning"] = 201] = "billing_general_warning";
    NoticeTypeEnum[NoticeTypeEnum["billing_currency_purchased"] = 202] = "billing_currency_purchased";
    NoticeTypeEnum[NoticeTypeEnum["billing_operation_completed"] = 203] = "billing_operation_completed";
    NoticeTypeEnum[NoticeTypeEnum["billing_operation_error"] = 204] = "billing_operation_error";
    NoticeTypeEnum[NoticeTypeEnum["billing_plan_was_changed"] = 205] = "billing_plan_was_changed";
    NoticeTypeEnum[NoticeTypeEnum["billing_card_update"] = 206] = "billing_card_update";
    NoticeTypeEnum[NoticeTypeEnum["billing_general_error"] = 207] = "billing_general_error";
    /**
     * Успешное пополнение баланса кредитов
     *
     * @see BillingCreditsPurchasedNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["billing_credits_purchased"] = 208] = "billing_credits_purchased";
    /**
     * Тариф был деактивирован
     *
     * @see BillingPlanWasDeactivatedNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["billing_plan_was_deactivated"] = 209] = "billing_plan_was_deactivated";
    /**
     * Подписка на тариф скоро будет продлена
     *
     * @see PlanWillBeProlongSoonNoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["billing_plan_will_be_prolong_soon"] = 210] = "billing_plan_will_be_prolong_soon";
    /** * -------------- Учебные классы -------------------- */
    /**
     * @deprecated
     * @see NoticeTypeEnum.classroom_new_participant_2
     */
    NoticeTypeEnum[NoticeTypeEnum["classroom_new_participant"] = 301] = "classroom_new_participant";
    /**
     * @deprecated
     * @see NoticeTypeEnum.classroom_new_invitation_2
     */
    NoticeTypeEnum[NoticeTypeEnum["classroom_new_invitation"] = 302] = "classroom_new_invitation";
    NoticeTypeEnum[NoticeTypeEnum["classroom_was_removed"] = 303] = "classroom_was_removed";
    NoticeTypeEnum[NoticeTypeEnum["classroom_user_leave"] = 304] = "classroom_user_leave";
    NoticeTypeEnum[NoticeTypeEnum["classroom_new_meeting"] = 305] = "classroom_new_meeting";
    /**
     * Ученик добавился в классную комнату
     *
     * @see ClassroomNewParticipant2NoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["classroom_new_participant_2"] = 306] = "classroom_new_participant_2";
    /**
     * Ученика пригласили в классную комнату
     *
     * @see ClassroomNewInvitation2NoticeParams
     */
    NoticeTypeEnum[NoticeTypeEnum["classroom_new_invitation_2"] = 307] = "classroom_new_invitation_2";
    /** * -------------- Школы -------------------- */
    /**
     * Изменился статус школы
     */
    NoticeTypeEnum[NoticeTypeEnum["school_status_changed"] = 401] = "school_status_changed";
    /** * -------------- Коллекции -------------------- */
    /**
     * Купили коллекцию
     *
     * @see ICollectionPayoutNotice
     */
    NoticeTypeEnum[NoticeTypeEnum["collection_payout"] = 500] = "collection_payout";
    /**
     * Use NoticeTypeEnum.collection_payout instead
     *
     * @see ICollectionPayoutNotice
     * @deprecated
     * @see NoticeTypeEnum.collection_payout
     */
    NoticeTypeEnum[NoticeTypeEnum["collection_was_bought"] = 500] = "collection_was_bought";
    /** -------------- Расписание -------------------- */
    NoticeTypeEnum[NoticeTypeEnum["schedule_vacation_error"] = 600] = "schedule_vacation_error";
    /** Вы установили режим отпуска с ... по ... */
    NoticeTypeEnum[NoticeTypeEnum["vacation_set_up"] = 601] = "vacation_set_up";
    /** У вас отпуск с ... по ... */
    NoticeTypeEnum[NoticeTypeEnum["vacation_reminder"] = 602] = "vacation_reminder";
})(NoticeTypeEnum = exports.NoticeTypeEnum || (exports.NoticeTypeEnum = {}));
