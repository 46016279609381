"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrecheckSchema = exports.SuccessSchema = exports.ErrorSchema = exports.BodySchema = exports.method = exports.path = void 0;
exports.parseBody = parseBody;
exports.resp_err = resp_err;
exports.resp_success = resp_success;
exports.resp_precheck = resp_precheck;
const zod_1 = require("zod");
const common_1 = require("../../../../common");
const audience_1 = require("../../../../shared/audience");
const lingo2_models_1 = require("lingo2-models");
/** относительно `${config.http.webAuthUrl}` */
exports.path = 'signup';
exports.method = common_1.HttpMethod.PUT;
// #region Request
exports.BodySchema = zod_1.z.object({
    audience: zod_1.z.nativeEnum(audience_1.EnumAudience).default(audience_1.EnumAudience.web).optional(),
    password: zod_1.z.string().trim().min(8, "Password too short"),
    email: zod_1.z.string().email().toLowerCase().trim(),
    mobile_phone: zod_1.z.string().trim().min(5, "Invalid mobile phone number")
        .regex(/[0-9]/, "Invalid mobile phone number")
        .transform((val) => val.replace(/[^\d]/g, '')),
    nick: zod_1.z.string().min(3, "Nic too short").regex(/[A-Za-z\.\_0-9]/, "Invalid nick").toLowerCase().trim().optional(),
    slug: zod_1.z.string().toLowerCase().trim().optional(),
    first_name: zod_1.z.string().trim().min(2, "First name invalid"),
    last_name: zod_1.z.string().trim().min(2, "Last name invalid"),
    teacher_status: zod_1.z.nativeEnum(lingo2_models_1.TeacherStatusEnum).default(lingo2_models_1.TeacherStatusEnum.not_teacher),
    country_id: zod_1.z.string().trim(),
    precheck: zod_1.z.boolean().default(false).optional(),
});
function parseBody(dto) {
    return exports.BodySchema.parse(dto);
}
// #endregion
// #region Response
exports.ErrorSchema = zod_1.z.object({
    reference: zod_1.z.string().optional(),
    message: zod_1.z.string(),
    details: zod_1.z.string().optional(),
});
function resp_err(dto) {
    return exports.ErrorSchema.parse(dto);
}
exports.SuccessSchema = zod_1.z.object({
    access: zod_1.z.string(),
    refresh: zod_1.z.string(),
    id: zod_1.z.string().uuid().optional(),
    slug: zod_1.z.string().optional(),
    first_name: zod_1.z.string().optional(),
    last_name: zod_1.z.string().optional(),
});
function resp_success(dto) {
    return exports.SuccessSchema.parse(dto);
}
exports.PrecheckSchema = zod_1.z.object({
    exist: zod_1.z.boolean().default(false),
});
function resp_precheck(dto) {
    return exports.PrecheckSchema.parse(dto);
}
// #endregion
