"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.UserProperties = void 0;
var enums_1 = require("./enums/");
var user_profile_1 = require("./user-profile");
/** для кого нужно экспортировать этот список ? */
exports.UserProperties = [
    'id',
    'slug',
    'first_name',
    'last_name',
    'userpic_id',
    'userpic',
    'cover_id',
    'cover',
    'country_id',
    'country',
    'spoken_languages',
    'subject_preferences',
    'ui_language',
    'timezone',
    'currency_id',
    'teacher_profile_verified',
    'last_active_at',
    'last_logged_in_at',
    'stats',
    'visit_info',
    'teacher_status',
    'teacher_review_at',
    'status',
    'is_guest',
    'created_at',
    'roles',
    'features',
    'segments',
    'trackers',
    'achievements',
    'rating',
    'purl',
    'hidden',
    'readonly',
    'mobile_phone',
    'logging',
    '__opts',
];
var dateProperties = ['created_at', 'last_active_at', 'last_logged_in_at', 'teacher_review_at'];
var User = /** @class */ (function () {
    function User(values) {
        if (values === void 0) { values = {}; }
        /** Языки, на которых говорит */
        this.spoken_languages = [];
        /** Выбранные дисциплины */
        this.subject_preferences = [];
        /** Роли */
        this.roles = [];
        /** Доступные фичи */
        this.features = [];
        /** Рейтинг */
        this.rating = 0;
        if (!values) {
            return null;
        }
        var properties = exports.UserProperties;
        var instance = this;
        properties.forEach(function (p) {
            if (p in values) {
                instance[p] = values[p];
            }
        });
        if ('profile' in values) {
            instance.profile = new user_profile_1.UserProfile(values.profile);
        }
        // преобразование дат
        dateProperties.forEach(function (p) {
            if (p in instance && instance[p]) {
                instance[p] = new Date(instance[p]);
            }
        });
        if ('segments' in instance) {
            instance.segments = instance.segments.map(function (segment) { return (__assign(__assign({}, segment), { created_at: new Date(segment.created_at) })); });
        }
    }
    Object.defineProperty(User.prototype, "title", {
        get: function () {
            return "".concat(this.first_name, " ").concat(this.last_name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User.prototype, "accountable_type", {
        /** Тип элемента для расчётов. Всегда имеет значение EAccountable.account */
        get: function () {
            return enums_1.EAccountableType.account;
        },
        enumerable: false,
        configurable: true
    });
    return User;
}());
exports.User = User;
