export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-conference-2',
    versionDate: '2024-10-11T14:25:01.802Z',
    gitCommitHash: 'g18a3350e',
    gitCommitDate: '2024-10-11T13:58:50.000Z',
    versionLong: '35.0.0-g18a3350e',
    gitTag: 'v35.0.0',
};
export default versions;
