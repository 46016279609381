"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanSchema = exports.PlanDetailsSchema = void 0;
const lingo2_models_1 = require("lingo2-models");
const zod_1 = require("zod");
const currency_amount_1 = require("./currency-amount");
const price_tier_1 = require("./price-tier");
exports.PlanDetailsSchema = zod_1.z.object({
    /** Сколько кредитов приобретается */
    buy_credits: zod_1.z.coerce.number(),
    /** Сколько добавить бонусных кредитов */
    bonus_credits: zod_1.z.coerce.number(),
    /** Провайдеры, которые могут принимать участие */
    payment_providers: zod_1.z.array(zod_1.z.nativeEnum(lingo2_models_1.BillingPaymentProviderEnum)),
    /** Название (ключ для локализации через i18n) */
    title: zod_1.z.string(),
    /** Дополнительное название (ключ для локализации через i18n) */
    subtitle: zod_1.z.string(),
    /** Подсказка (ключ для локализации через i18n) */
    hint: zod_1.z.string(),
    /** Подсказка к бонусам (ключ для локализации через i18n) */
    bonus_hint: zod_1.z.string(),
    /** Визуальная пометка  (ключ для локализации через i18n) */
    label: zod_1.z.string(),
    /** Скидки на покупку валюты */
    currency_discounts: zod_1.z.array(currency_amount_1.CurrencyAmountSchema),
    /** Выдаваемая валюта */
    currency_grants: zod_1.z.array(currency_amount_1.CurrencyAmountSchema),
    /** Какое-то название */
    nameplate: zod_1.z.string(),
    /** Правила для тарифа */
    tariff_terms: zod_1.z.array(zod_1.z.any()),
    /** Старый priceTierId */
    old_price_tier_id: zod_1.z.coerce.number(),
    /** Комиссия безопасных сделок */
    safe_trade_commission: zod_1.z.coerce.number(),
});
exports.PlanSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    tenant: zod_1.z.nativeEnum(lingo2_models_1.TenantEnum),
    status: zod_1.z.nativeEnum(lingo2_models_1.PlanStatusEnum),
    order: zod_1.z.number(),
    details: exports.PlanDetailsSchema.partial().optional(),
    price_tier_id: zod_1.z.number().or(zod_1.z.null()),
    period: zod_1.z.nativeEnum(lingo2_models_1.PlanPeriodEnum).default(lingo2_models_1.PlanPeriodEnum.month1),
    is_default: zod_1.z.boolean().default(false),
    is_visible: zod_1.z.boolean().default(true),
    is_trial: zod_1.z.boolean().default(false),
    next_plan_id_after_trial: zod_1.z.string().uuid().or(zod_1.z.null()).default(null),
    created_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    updated_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    deleted_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    priceTier: price_tier_1.PriceTierSchema.optional(),
});
