import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
 */
import { environment } from '@environments/environment';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, catchError, concatMap, filter, Observable, switchMap, take } from 'rxjs';

/*
 */
export interface ITokens {
  access: string;
  refresh?: string;
}

/*
 */
const DEF_HOST: string = environment.default_host;
const ACCESS_KEY: string = environment.access_token_key;
const REFRESH_KEY: string = environment.refresh_token_key;
const DEF_OPTS = { domain: `${environment.cookie_domain}`, path: '/' };
const DEF_HEADERS = { 'INTERCONN-KEY': environment.interconn_key }; // Todo

/*
 */
@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  private token_refreshed$ = new BehaviorSubject<boolean>(false);
  private refreshing = false;
  private skip_refresh = false;

  /*
   */
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  /*
   */
  public skipRefresh = (v: boolean) => (this.skip_refresh = v);

  /*
   */
  public getAuthString = () => `Bearer ${this.getAccessToken()}`;
  public getAuthHeader = () => ({ Authorization: this.getAuthString() });
  public getReqWithAuth = (r) => r.clone(this.getAccessToken()?.length ? { setHeaders: this.getAuthHeader() } : {});

  public decode = (v: string): any => jwtDecode(v);
  public refreshable = () => this.getRefreshToken()?.length;
  public getAccessToken = (): string => this.cookieService.get(ACCESS_KEY) || null;
  public getRefreshToken = (): string => this.cookieService.get(REFRESH_KEY);

  /*
   */
  public setAccessToken = (v: string) => {
    this.cookieService.put(ACCESS_KEY, v, DEF_OPTS);
  };

  /*
   */
  public setRefreshToken = (v: string) => {
    this.cookieService.put(REFRESH_KEY, v, DEF_OPTS);
  };


  /*
   */
  public clearAuth = () => {
    this.setAccessToken(null);
    this.setRefreshToken(null);
  };

  /* This is tricky way, but we need hard reload after signout
   */
  public force_reload = () => {
    // setTimeout(() => {
    //   const url = new URL('/', DEF_HOST);
    //   url.searchParams.set('s', Date.now().toString());
    //   window.location.href = url.toString();
    // }, 100);
  };

  /*
   */
  public force_logout = () => {
    this.clearAuth();
    this.force_reload();
  };

  /*
   */
  public getTokenUser(): any {
    try {
      return jwtDecode<any>(this.getAccessToken())?.subject;
    } catch (err) {
      console.error('@@@@ AccessTokenService.getTokenUser(): Error: ', err);
    }
  }

  /*
   */
  public refresh_token(): Observable<any> {
    const refresh = this.getRefreshToken();
    return this.http.post(`${environment.token_url}/refresh`, { refresh }, { headers: DEF_HEADERS });
  }

  /*
   */
  public process_request(req, next): Observable<any> {
    if (this.refreshing) {
      return this.token_refreshed$.pipe(
        filter((res) => res !== false),
        take(1),
        concatMap(() => next(this.getReqWithAuth(req))),
      );
    }

    this.refreshing = true;
    this.token_refreshed$.next(false);
    return this.refresh_token().pipe(
      switchMap((res: ITokens) => {
        if (res) {
          this.setAccessToken(res.access);
          this.setRefreshToken(res.refresh);
          this.token_refreshed$.next(true);
          this.refreshing = false;
          return next(this.getReqWithAuth(req));
        }
      }),
      catchError((err) => {
        if (err.status === 410) {
          this.refreshing = false;
          console.debug('@@@@ AccessTokenService.process_request(): Error: ', err);
          this.force_logout();
          return next(this.getReqWithAuth(req));
        }
        return next(this.getReqWithAuth(req));
      }),
    ) as Observable<HttpEvent<any>>;
  }
}
