"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.versions = void 0;
;
exports.versions = {
    version: '1.0.43',
    name: 'lingo2-api-models',
    versionDate: '2024-10-11T08:59:45.588Z',
    description: 'Shared api models library',
    gitCommitHash: 'gcdd4343',
    gitCommitDate: '2024-10-11T08:59:36.000Z',
    versionLong: '1.0.43-gcdd4343',
    gitTag: 'v1.0.39',
};
exports.default = exports.versions;
