"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
var account_1 = require("../../account");
var collection_item_1 = require("./collection-item");
var collection_license_enum_1 = require("./collection-license-enum");
var properties = [
    'id',
    'title',
    'excerpt',
    'description',
    'price_tiers',
    'slug',
    'cover_id',
    'cover',
    'multi_cover',
    'multi_cover_id',
    'author_id',
    'language_id',
    'language',
    'subject_id',
    'subject',
    'subject_other_name',
    'subject_other_id',
    'discount',
    'category_id',
    'category',
    'keywords',
    'type',
    'stats',
    'visit_info',
    'options',
    'moderation_status',
    'publish_status',
    'moderation_message',
    'promotion',
    'promotion_status',
    'advertisement',
    'advertisement_status',
    'access',
    'purl',
    'hidden',
    'valid',
    'readonly',
    'can',
    'is',
    '__opts',
];
var dateProperties = ['updated_at', 'created_at', 'changed_at'];
/** Коллекция */
var Collection = /** @class */ (function () {
    function Collection(values) {
        if (values === void 0) { values = {}; }
        /** Скидка в % */
        this.discount = 0;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var instance = this;
        properties.forEach(function (p) {
            if (p in values) {
                instance[p] = values[p];
            }
        });
        if ('author' in values) {
            instance.author = new account_1.User(values.author);
        }
        instance.items = (values.items || []).map(function (p) { return new collection_item_1.CollectionItem(p); });
        // преобразование дат
        dateProperties.forEach(function (p) {
            if (p in instance && instance[p]) {
                instance[p] = new Date(String(instance[p]));
            }
        });
    }
    return Collection;
}());
exports.Collection = Collection;
