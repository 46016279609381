"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Content = void 0;
var account_1 = require("../account");
var comment_1 = require("../comment");
var school_1 = require("../school");
var classroom_1 = require("./classroom");
var collection_1 = require("./collection");
var ContentDateProperties = ['published_at', 'archived_at', 'created_at', 'deleted_at'];
var Content = /** @class */ (function () {
    function Content(values) {
        if (values === void 0) { values = {}; }
        /** Название */
        this.title = '';
        /** Дополнительное название */
        this.sub_title = '';
        /** Описание */
        this.description = '';
        this.applyValues(values);
    }
    Object.defineProperty(Content.prototype, "is_published", {
        get: function () {
            return !!this.published_at;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "is_archived", {
        get: function () {
            return !!this.archived_at;
        },
        enumerable: false,
        configurable: true
    });
    Content.createContent = function (values) {
        var instance = new this();
        return instance.applyValues(values);
    };
    Content.prototype.getClassProperties = function () {
        return [
            'id',
            'author_id',
            'slug',
            'title',
            'sub_title',
            'type',
            'cover_id',
            'cover',
            'description',
            'language_id',
            'language',
            'level',
            'level_id',
            'subject',
            'subject_id',
            'subject_other_name',
            'level_other_name',
            'classroom_id',
            'school_id',
            'created_at',
            'published_at',
            'archived_at',
            'stats',
            'visit_info',
            'moderation_status',
            'promotion',
            'promotion_status',
            'advertisement',
            'advertisement_status',
            'privacy',
            'template',
            'template_title',
            'by_template_id',
            'template_options',
            'purl',
            'hidden',
            'valid',
            'readonly',
            '__opts',
            'media_url',
        ];
    };
    Content.prototype.applyValues = function (values) {
        var instance = this;
        var properties = instance.getClassProperties();
        properties.forEach(function (p) {
            if (typeof p === 'string' && p in values) {
                instance[p] = values[p];
            }
        });
        if ('author' in values) {
            instance.author = new account_1.User(values.author);
        }
        if ('comments' in values) {
            instance.comments = {
                total: values.comments.total,
                first: (values.comments.first || []).map(function (c) { return new comment_1.Comment(c); }),
                last: (values.comments.last || []).map(function (c) { return new comment_1.Comment(c); }),
            };
        }
        if ('byTemplate' in values) {
            instance.byTemplate = new Content(values.byTemplate);
        }
        // преобразование дат
        ContentDateProperties.forEach(function (p) {
            if (p in instance && instance[p]) {
                instance[p] = new Date(instance[p]);
            }
        });
        if ('collections' in values) {
            instance.collections = values.collections.map(function (v) { return new collection_1.Collection(v); });
        }
        if ('classroom' in values) {
            instance.classroom = new classroom_1.Classroom(values.classroom);
        }
        if ('school' in values) {
            instance.school = new school_1.CSchool(values.school);
        }
        return this;
    };
    return Content;
}());
exports.Content = Content;
