"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountSchema = void 0;
const zod_1 = require("zod");
const image_file_1 = require("./image-file");
const country_1 = require("./country");
const spoken_language_1 = require("./spoken-language");
const subject_preferences_1 = require("./subject-preferences");
const lingo2_models_1 = require("lingo2-models");
const account_stats_1 = require("./account-stats");
const account_visit_info_1 = require("./account-visit-info");
const account_segment_1 = require("./account-segment");
const account_achievement_1 = require("./account-achievement");
const account_tracker_1 = require("./account-tracker");
const account_profile_1 = require("./account-profile");
const account_feature_1 = require("./account-feature");
exports.AccountSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    /** Уникальный код для формирования ссылки */
    slug: zod_1.z.string(),
    /** Имя */
    first_name: zod_1.z.string(),
    /** Фамилия */
    last_name: zod_1.z.string(),
    /** Аватара */
    userpic_id: zod_1.z.string().uuid().optional(),
    userpic: zod_1.z.record(image_file_1.ImageFileSchema).optional(),
    /** Обложка */
    cover_id: zod_1.z.string().uuid().optional(),
    cover: zod_1.z.record(image_file_1.ImageFileSchema).optional(),
    country_id: zod_1.z.string().uuid().optional(),
    country: country_1.CountrySchema.optional(),
    /** Дата регистрации. */
    created_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    /** Языки, на которых говорит */
    spoken_languages: zod_1.z.array(spoken_language_1.SpokenLanguageSchema).default([]).optional(),
    /** Выбранные дисциплины */
    subject_preferences: zod_1.z.array(subject_preferences_1.SubjectPreferencesSchema).default([]).optional(),
    /** Статус преподавателя */
    teacher_status: zod_1.z.nativeEnum(lingo2_models_1.TeacherStatusEnum).optional(),
    /** Дата очередного пересмотра статуса преподавателя */
    teacher_review_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    /** Дата последней активности в проекте */
    last_active_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    /** Дата последнего логина в проекте */
    last_logged_in_at: zod_1.z.string().datetime().or(zod_1.z.date()).or(zod_1.z.null()).optional(),
    /** Пользовательская статистика */
    stats: account_stats_1.AccountStatsSchema.partial().optional(),
    /** Информация для текущего пользователя */
    visit_info: account_visit_info_1.AccountVisitInfoSchema.partial().optional(),
    /** Статус пользователя */
    status: zod_1.z.nativeEnum(lingo2_models_1.UserStatusEnum).optional(),
    /**
     * Признак - Является ли гостём
     *
     * @deprecated
     * @see User.status === UserStatusEnum.guest
     */
    is_guest: zod_1.z.boolean().optional(),
    /** Сегменты (признаки) пользователя */
    segments: zod_1.z.array(account_segment_1.AccountSegmentSchema).optional(),
    /** Достижения пользователя */
    achievements: zod_1.z.array(account_achievement_1.AccountAchievementSchema).optional(),
    /** Трекеры действий пользователя */
    trackers: zod_1.z.array(account_tracker_1.AccountTrackerSchema).optional(),
    /**
     * @description Поддерживает ли безопасную сделку
     * @deprecated
     */
    safe_deal_available: zod_1.z.boolean().optional(),
    /** Код языка интерфейса */
    ui_language: zod_1.z.string().optional(),
    /**
     * Название таймзоны
     *
     * @example Europe/Moscow
     */
    timezone: zod_1.z.string().optional(),
    /** Валюта цен */
    currency_id: zod_1.z.nativeEnum(lingo2_models_1.CurrencyEnum).optional(),
    /** Профиль преподавателя */
    profile: account_profile_1.AccountProfileSchema.partial().optional(),
    /** Профиль преподавателя верифицирован */
    teacher_profile_verified: zod_1.z.boolean().optional(),
    /** Роли */
    roles: zod_1.z.array(zod_1.z.nativeEnum(lingo2_models_1.UserRoleEnum)).default([]).optional(),
    /** Доступные фичи */
    features: zod_1.z.array(account_feature_1.AccountFeatureSchema).default([]).optional(),
    /** Пользователь скрыт */
    hidden: zod_1.z.boolean().optional(),
    /** Запись пользователя защищена от изменения */
    readonly: zod_1.z.boolean().optional(),
    /** Рейтинг */
    rating: zod_1.z.number().default(0).optional(),
    /** Телефон */
    mobile_phone: zod_1.z.string().optional(),
    /** ссылка в панель управления на страницу этой сущности */
    purl: zod_1.z.string().url().optional(),
    /** Режим протоколирования работы */
    logging: zod_1.z.boolean().optional(),
});
