"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.versions = void 0;
;
exports.versions = {
    version: '1.154.53',
    name: 'lingo2-models',
    versionDate: '2024-10-02T07:36:29.457Z',
    description: 'Shared models library',
    gitCommitHash: 'g41dc25b',
    gitCommitDate: '2024-10-02T07:36:06.000Z',
    versionLong: '1.154.53-g41dc25b',
    gitTag: 'v1.154.53',
};
exports.default = exports.versions;
