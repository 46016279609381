"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RatingIncreasedNoticeParams = void 0;
var enums_1 = require("../enums");
var abstract_notice_params_1 = require("./abstract-notice-params");
/** Your rating has been increased */
var RatingIncreasedNoticeParams = /** @class */ (function (_super) {
    __extends(RatingIncreasedNoticeParams, _super);
    function RatingIncreasedNoticeParams() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = enums_1.NoticeTypeEnum.rating_increased;
        return _this;
    }
    RatingIncreasedNoticeParams.prototype.getClassProperties = function () {
        var properties = ['rating'];
        return __spreadArray(__spreadArray([], _super.prototype.getClassProperties.call(this), true), properties, true);
    };
    return RatingIncreasedNoticeParams;
}(abstract_notice_params_1.AbstractNoticeParams));
exports.RatingIncreasedNoticeParams = RatingIncreasedNoticeParams;
